<template>
    <div class="pretty-select" :class="getColorClass">
        <label>{{ localLabel }}</label>
        <v-select ref="select" :value="localValue" v-bind="selectProps" v-on="$listeners" :calculate-position="withPopper">
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
        </v-select>
    </div>
</template>
<script>
import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'
export default {
    name: 'PrettySelect',
    components: { vSelect },
    props: {
        value: {
            default: null,
        },
        filterable: {
            default: true,
        },
        options: {
            default: null,
        },
        label: {
            default: null,
        },
        getOptionLabel: {
            default: undefined,
        },
        reduce: {
            default: undefined,
        },
        placeholder: {
            default: null,
        },
        customPlaceholder: {
            default: null,
        },
        preventTextWrap: {
            default: false,
        },
        shrunkLabel: {
            default: false,
        },
        color: {
            default: null,
        },
        required: {
            default: undefined,
        },
        multiple: {
            default: false,
        },
        taggable: {
            default: false,
        },
        clearable: {
            default: true,
        },
        appendToBody: {
            default: true,
        },
        disabled: {
            default: false,
        },
        autoSelectFirstOption: {
            default: false,
        },
        attrs: {
            default: false,
        },
        getOptionKey: {
            type: Function,
            default (option) {
                if (typeof option === 'object' && option.key) {
                    return option.key
                } else {
                    try {
                        return JSON.stringify(option)
                    } catch (e) {
                        return null
                    }
                }
            },
        },
    },
    computed: {
        localLabel () {
            if (typeof this.required !== 'undefined') {
                return this.required !== false ? `${this.placeholder} *` : `${this.placeholder}`
            }

            return this.placeholder
        },
        localPlaceholder () {
            const placeholder = this.customPlaceholder || this.placeholder
            if (typeof this.required !== 'undefined' && this.required !== false) {
                return `${placeholder} *`
            }

            return placeholder
        },
        selectProps () {
            return {
                ...this.$props,
                placeholder: this.localPlaceholder,
            }
        },
        localValue () {
            return this.value
        },
        getColorClass () {
            let classes = ''

            if (this.preventTextWrap) {
                classes += 'pretty-select--no-wrap '
            }

            if (this.shrunkLabel) {
                classes += 'pretty-select--shrunk-label '
            }

            if (this.color) {
                classes += `pretty-select--${this.color}`
            }

            return classes
        },
    },
    watch: {
        value () {
            if (!this.value) {
                this.$refs.select.clearSelection()
            }
        },
    },
    methods: {
        updateValues () {
            this.$set(this.$refs.select._data, '_value', this.value)
        },
        withPopper (dropdownList, component, { width }) {
            dropdownList.style.width = width

            const popper = createPopper(component.$refs.search, dropdownList, {
                placement: 'bottom',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 5],
                        },
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn ({ state }) {
                            component.$el.classList.toggle('drop-up', state.placement === 'top')
                        },
                    },
                ],
            })

            return () => popper.destroy()
        },
    },
    created () {
        if (this.autoSelectFirstOption) {
            this.$emit('input', this.reduce ? this.reduce(this.options[0]) : this.options[0])
            this.$nextTick(() => {
                this.$set(this.$refs.select._data, '_value', this.options[0])
            })
        }
    },
}
</script>
