<template>
    <div class="c-sidebar">
        <mercur-item :to="{ name: 'AgentsWrapper' }" :class="{ 'c-sidebar__active-route' : setActiveRoute('agents') }">
            <i class="fas fa-user-friends"></i><span>Agents</span>
        </mercur-item>
        <mercur-item :class="{ 'c-sidebar__active-route-parent' : setActiveRoute('customers') }" :expand="true">
            <i class="fas fa-user-friends"></i><span>Customers</span>
            <template #content>
                <mercur-item :to="{ name: 'CustomersWrapper' }">
                    <i class="fas fa-chevron-right"></i><span>Overview</span>
                </mercur-item>
                <mercur-item :to="{ name: 'CustomersGroupsWrapper' }">
                    <i class="fas fa-chevron-right"></i><span>Groups</span>
                </mercur-item>
                <mercur-item :to="{ name: 'CustomersConfigurationsWrapper' }">
                    <i class="fas fa-chevron-right"></i><span>Account Configuration</span>
                </mercur-item>
            </template>
        </mercur-item>
        <mercur-item :to="{ name: 'RolesWrapper' }" :class="{ 'c-sidebar__active-route' : setActiveRoute('roles') }">
            <i class="fas fa-user-tag"></i><span>Roles</span>
        </mercur-item>
        <mercur-item :to="{ name: 'PermissionsWrapper' }" :class="{ 'c-sidebar__active-route' : setActiveRoute('permissions') }">
            <i class="fas fa-ban"></i><span>Permissions</span>
        </mercur-item>
        <mercur-item data-e2e="catalogLink" :class="{ 'c-sidebar__active-route-parent' : setActiveRoute('catalog') }" :expand="true">
            <i class="far fa-list-alt"></i><span>Catalog</span>
            <template #content>
                <mercur-item data-e2e="marketplaceLink" :to="{ name: 'CatalogWrapperMarket'}">
                    <i class="fas fa-chevron-right"></i><span>Marketplace</span>
                </mercur-item>
                <mercur-item data-e2e="catalogChildLink" :to="{ name: 'CatalogWrapper'}">
                    <i class="fas fa-chevron-right"></i><span>Catalog</span>
                </mercur-item>
            </template>
        </mercur-item>
        <mercur-item :class="{ 'c-sidebar__active-route-parent' : setActiveRoute('vat-config') }" :expand="true">
            <i class="fas fa-clipboard-list"></i><span>Vat Config</span>
            <template #content>
                <mercur-item :to="{ name: 'VatConfigWrapper' }">
                    <i class="fas fa-chevron-right"></i><span>Vat profiles</span>
                </mercur-item>
                <mercur-item :to="{ name: 'CompanyProfiles' }">
                    <i class="fas fa-chevron-right"></i><span>Company profiles</span>
                </mercur-item>
            </template>
        </mercur-item>
        <mercur-item :to="{ name: 'SmartSpyWrapper' }" :class="{ 'c-sidebar__active-route' : setActiveRoute('smartspy') }">
            <i class="fas fa-euro-sign"></i><span>SmartSpy</span>
        </mercur-item>
        <mercur-item :to="{ name: 'FastEditorWrapper' }" :class="{ 'c-sidebar__active-route' : setActiveRoute('fasteditor') }">
            <i class="fas fa-image"></i><span>FastEditor</span>
        </mercur-item>
    </div>
</template>

<script>

export default {
    name: 'Sidebar',
    data () {
        return {
            expandSingle: false,
        }
    },
    methods: {
        setActiveRoute (path) {
            const filtered = this.$route.path.split('/').filter((el) => {
                return el !== ''
            })

            return (path === filtered[0])
        },
    },
}
</script>

<style lang="scss" scoped>
.mercur-item i {
    vertical-align: middle;
}

.c-app .c-sidebar__active-route i {
    color: black;
}
</style>
